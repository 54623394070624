import React, { useEffect, useState } from 'react'
import Header from '../wrapper/header'
import axios from 'axios'
import cookies from 'react-cookies'
import { useDispatch, useSelector } from 'react-redux'
import { auth0Roles, strapiURL } from '../../config'
import { SetLoader } from '../../redux/actions/loader'
import EmployerDashBoardSidebar from './sidebar'
import SEO from '../pageComponents/common/helmetCompont'
import {
  logout,
  setProducts,
  setProfileData,
  setNamedUserEmployerProfiles,
} from '../../redux/actions/user'

const EmployerDashboardLayout = ({ location, children, title }) => {
  let user = cookies.load('user')
  let userRole = cookies.load('userRole')
  let selectedNamedUserEmployerId = cookies.load('selectedNamedUserEmployerId')

  const dispatch = useDispatch()
  const loader = useSelector(state => state.loader)
  const profileData = useSelector(state => state.user.profileData)
  const namedUserEmployerProfiles = useSelector(
    state => state.user.namedUserEmployerProfiles
  )
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!user || !userRole || !profileData) {
      const urlSearchParams = new URLSearchParams(location?.search)
      const source = urlSearchParams.get('utm_source')
      if (source === 'email') {
        localStorage.setItem('redirect-url', location.href)
        dispatch(logout({ utm_source: 'email' }))
      } else {
        dispatch(logout())
      }
    }
    if (
      user &&
      userRole !== auth0Roles.Employer.id &&
      userRole !== auth0Roles.NamedUser.id
    ) {
      dispatch(logout('Please login with a employer or named user account.'))
    }
    if (
      userRole === auth0Roles.NamedUser.id &&
      (!namedUserEmployerProfiles || !selectedNamedUserEmployerId)
    ) {
      dispatch(logout())
    }

    if (userRole === auth0Roles.Employer.id) {
      if (!profileData) {
        loadEmployerProfile()
      }
    } else if (userRole === auth0Roles.NamedUser.id) {
      if (!namedUserEmployerProfiles) {
        loadNamedUserProfile()
      } else {
        if (selectedNamedUserEmployerId) {
          if (!profileData.id == selectedNamedUserEmployerId) {
            const selectedEmployer = namedUserEmployerProfiles.find(
              profile => profile.id == selectedNamedUserEmployerId
            )
            dispatch(setProfileData(selectedEmployer))
          }
        } else {
          dispatch(setProfileData(namedUserEmployerProfiles[0]))
          cookies.save('selectedNamedUserEmployerId', profileData.id, {
            path: '/',
            maxAge: 518400,
          })
        }
      }
    }
    setLoading(false)
  }, [
    user,
    userRole,
    namedUserEmployerProfiles,
    profileData,
    selectedNamedUserEmployerId,
    location.search,
  ])

  const loadEmployerProfile = () => {
    dispatch(SetLoader(true))
    axios
      .post(strapiURL + '/employers/profile', {
        username: user?.username,
      })
      .then(res => {
        dispatch(setProfileData(res.data))
        dispatch(SetLoader(false))
      })
      .catch(err => {
        dispatch(SetLoader(false))
        console.log(err)
      })
  }

  const loadNamedUserProfile = () => {
    dispatch(SetLoader(true))
    axios
      .post(strapiURL + '/named-users/getNamedUsers', {
        username: user?.username,
      })
      .then(res => {
        const namedUsers = res.data
        const namedUserProfiles = namedUsers?.map(user => user.employer)

        let profile
        if (namedUserProfiles.length) {
          if (selectedNamedUserEmployerId) {
            profile = namedUserProfiles.find(
              profile => profile.id == selectedNamedUserEmployerId
            )
          } else {
            profile = namedUserProfiles[0]
          }
        }

        cookies.save('selectedNamedUserEmployerId', profile.id, {
          path: '/',
          maxAge: 518400,
        })
        dispatch(setProfileData(profile))
        dispatch(setNamedUserEmployerProfiles(namedUserProfiles))
        dispatch(SetLoader(false))
      })
      .catch(err => {
        dispatch(SetLoader(false))
        console.log(err)
      })
  }

  const loadUserProfile = () => {
    if (!user || !userRole) {
      return
    }

    if (userRole === auth0Roles.Employer.id) {
      loadEmployerProfile()
    } else if (userRole === auth0Roles.NamedUser.id) {
      loadNamedUserProfile()
    }
  }

  useEffect(() => {
    if (userRole === auth0Roles.Employer.id) {
      handleLoadUserSubscribedProducts(user.user.id)
    } else if (userRole === auth0Roles.NamedUser.id) {
      handleLoadUserSubscribedProducts(profileData.user.id)
    }
  }, [user, profileData, userRole])

  const handleLoadUserSubscribedProducts = employerId => {
    axios
      .get(strapiURL + `/employers/${employerId}/subscriptionsAndCredits`)
      .then(res => {
        dispatch(setProducts(res?.data))
      })
  }

  const setUserProfile = profile => {
    dispatch(setProfileData(profile))
  }

  return (
    <div>
      <Header
        globalData={null}
        loader={loader?.loading}
        isEmployerDashboard={true}
        location={location}
        user={user}
        userRole={userRole}
      />
      <SEO title={title} />
      <div className="flex">
        <div className="hidden lg:block">
          <EmployerDashBoardSidebar location={location} user={user} />
        </div>
        <div className="flex-1 px-6 xl:px-9 pb-12 w-full">
          <div className="w-full">
            {!loading &&
              React.cloneElement(children, {
                userProfile: profileData,
                user: user,
                userRole: userRole,
                location: location,
                loadUserProfile,
                setUserProfile,
              })}
          </div>
        </div>
      </div>
    </div>
  )
}
export default EmployerDashboardLayout
