import React, { useEffect, useState } from 'react'
import { ArrowIcon } from '../../icons'
import { Form, Input, Select, message, DatePicker } from 'antd'
import {
  permissionsHandler,
  _subscriptionsSutructureData,
  getDecodedLocation,
} from '../../../functions'
import GooglePlacesAutocomplete from '../../google-place-autocomplete'
import { strapiURL } from '../../../config'
import axios from 'axios'
import CsvDownloader from 'react-csv-downloader'
import { useSelector } from 'react-redux'
import CreditDetail from '../../creditDetail'
import moment from 'moment'
import JobTable from '../jobTable'
import { dateFormatHandler } from '../../../functions'
import { getJobLocation } from '../jobTable'

const ManageJobsComponent = ({ userProfile, user, location }) => {
  const storeProducts = useSelector(state => state.user).products
  let products = permissionsHandler(storeProducts, user?.role?.id)
  const [jobs, setJobs] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [filters, setFilters] = useState({})
  const [showFilter, setShowFilter] = useState(false)
  const [loading, setLoading] = useState(true)
  const [sortBy, setSortBy] = useState('expiryDate')

  const [filterErrorMessage, setFilterErrorMessage] = useState(false)
  const [archiveJobId, setArchiveJobId] = useState(null)
  const [deleteJobId, setDeleteJobId] = useState(null)
  const [form] = Form.useForm()
  const { Option } = Select
  const { RangePicker } = DatePicker

  const loadUserJobs = async page => {
    const queryParams = await getJobQueryParam(page)

    axios
      .get(strapiURL + `/jobs/employer/${userProfile.id}?${queryParams}`)
      .then(response => {
        setTotal(response.data.total)
        setJobs(response.data.jobs)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  const getJobQueryParam = async page => {
    const {
      title,
      jobCode,
      featured,
      postedDate,
      expiryDate,
      postedBy,
      status,
      location,
    } = filters

    let queryParams = {}

    if (location?.value?.place_id) {
      const decodedLocation = await getDecodedLocation(
        filters?.location?.value?.place_id
      )
      if (decodedLocation) {
        queryParams.city = decodedLocation.city
        queryParams.state = decodedLocation.state
        queryParams.country = decodedLocation.country
        queryParams.geoCode = `${decodedLocation.latitude}, ${decodedLocation.longitude}`
      }
    }
    if (title) {
      queryParams.title = title
    }
    if (jobCode) {
      queryParams.jobCode = jobCode
    }
    if (postedBy) {
      queryParams.postedBy = postedBy
    }
    if (status) {
      queryParams.status = status
    }
    if (featured) {
      queryParams.featured = featured === 'featured'
    }
    if (postedDate && postedDate.length === 2) {
      queryParams.postedDateStart = postedDate[0]
      queryParams.postedDateEnd = postedDate[1]
    }
    if (expiryDate && expiryDate.length === 2) {
      queryParams.expiryDateStart = expiryDate[0]
      queryParams.expiryDateEnd = expiryDate[1]
    }
    if (sortBy) {
      queryParams.sortBy = sortBy
    }
    if (page) {
      queryParams.page = page
      queryParams.pageSize = pageSize
    } else {
      queryParams.disablePage = true
    }

    queryParams = new URLSearchParams(queryParams)
    return queryParams
  }

  const handleChangePage = page => {
    setPage(page)
  }

  const handleChangePageSize = pageSize => {
    setPageSize(pageSize)
  }

  useEffect(() => {
    loadUserJobs(page)
  }, [page, pageSize])

  useEffect(() => {
    setPage(1)
    loadUserJobs(1)
  }, [filters, sortBy])

  const archiveJob = record => {
    setArchiveJobId(record.id)
    let data = {
      archived_date: new Date(),
      employer: record?.employer,
    }

    axios
      .put(strapiURL + `/jobs/${record.id}`, data)
      .then(res => {
        message.success('Job expired successfully')
        setArchiveJobId(null)
        loadUserJobs(page)
      })
      .catch(err => {
        console.log(err, 'check err')
        message.error('Something went wrong.')
        setArchiveJobId(null)
      })
  }

  const deleteJob = record => {
    setDeleteJobId(record.id)
    let data = {
      is_deleted: true,
    }

    axios
      .put(strapiURL + `/jobs/${record.id}`, data)
      .then(res => {
        message.success('Job deleted successfully')
        setDeleteJobId(null)
        loadUserJobs(page)
      })
      .catch(err => {
        console.log(err, 'check err')
        message.error('Something went wrong.')
        setDeleteJobId(null)
      })
  }

  const submitHandler = values => {
    setFilters(values)
  }

  const csvHeader = [
    {
      id: 'id',
      displayName: 'ID',
    },
    {
      id: 'title',
      displayName: 'Job Title',
    },
    {
      id: 'location',
      displayName: 'Address',
    },
    {
      id: 'expiration_date',
      displayName: 'Expire Date',
    },
    {
      id: 'views',
      displayName: 'Views',
    },
    {
      id: 'job_code',
      displayName: 'Job Code',
    },
    {
      id: 'slug',
      displayName: 'Slug',
    },
    {
      id: 'employer',
      displayName: 'Employer Name',
    },
    {
      id: 'maxSalary',
      displayName: 'Max Salary',
    },
    {
      id: 'minSalary',
      displayName: 'Min Salary',
    },
    {
      id: 'posted_by',
      displayName: 'Posted By',
    },
    {
      id: 'min_compensation',
      displayName: 'Min Compensation',
    },

    {
      id: 'max_compensation',
      displayName: 'Max Compensation',
    },
    {
      id: 'published_at',
      displayName: 'Published Date',
    },
    {
      id: 'applications',
      displayName: 'Applications',
    },
  ]

  const exportHandler = async () => {
    const queryParams = await getJobQueryParam()
    const result = await axios.get(
      strapiURL + `/jobs/employer/${userProfile.id}?${queryParams}`
    )

    let _data = [...result.data.jobs]
    let newData = []
    _data.forEach(item => {
      const expiry = moment(item?.postedDate).add(30, 'days').toDate()

      newData.push({
        id: item?.id,
        title: item?.title,
        location: getJobLocation(item),
        expiration_date: item?.archivedDate
          ? dateFormatHandler(item?.archivedDate)
          : !item.postedDate
          ? 'Draft'
          : dateFormatHandler(expiry),
        views: item?.views || 0,
        job_code: item?.jobCode,
        slug: item?.slug,
        employer: item?.employer,
        maxSalary: item?.maxSalary,
        minSalary: item?.minSalary,
        posted_by: item?.postedBy,
        min_compensation: item?.minCompensation,
        max_compensation: item?.maxCompensation,
        published_at: item?.postedDate,
        applications: item?.applications || 0,
      })
    })
    return Promise.resolve(newData)
  }

  const postJobHandler = () => {
    if (!products?.subscriptionPurchased && !products?.singleJob) {
      message.info('Please purchase a subscription to post or update jobs.')
      return
    }
    window.location.href = '/employer-dashboard/jobs/create'
  }

  return (
    <div className="mt-6 lg:mt-0">
      <div className="flex justify-between items-end relative ">
        <h1 className="font-playfair text-headingThree font-semibold text-merlot">
          Manage Jobs
        </h1>
      </div>
      <div className="pt-4 flex justify-end">
        <button
          onClick={() => {
            form.resetFields()
            setFilterErrorMessage(false)
            setShowFilter(!showFilter)
          }}
          type="button"
          className="bg-bgSecondaryButton py-3 px-6 text-voyage font-bold rounded text-xs font-sans flex items-center"
        >
          Filter
          <div className={`${!showFilter ? 'rotate-180' : ''} ml-2`}>
            <ArrowIcon />
          </div>
        </button>
      </div>
      {filterErrorMessage && (
        <div className="border-2 border-red-500 w-[20%] bg-red-100 absolute -mt-2">
          <p className="text-red-500 m-0 py-2 font-sans font-semibold pl-2">
            Please select a filter.
          </p>
        </div>
      )}
      <Form
        className={`${
          showFilter ? `max-h-max pt-12 visible` : 'max-h-0 pt-0 invisible'
        } w-full ease-in-out manage-filter`}
        form={form}
        name="create-job-form"
        layout="vertical"
        onFinish={submitHandler}
      >
        <div className="flex flex-wrap flex-col lg:flex-row justify-between lg:items-end gap-3 mb-4">
          <Form.Item
            name="title"
            label="Title"
            className="w-full md:w-[30%] theme-form-item mb-0"
          >
            <Input
              type="text"
              placeholder="Job Title"
              className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
            />
          </Form.Item>
          <Form.Item
            name="featured"
            label="Featured"
            className="w-full md:w-[10%] theme-form-item mb-0"
          >
            <Select placeholder="Enter Featured ">
              <Option value="featured">Featured</Option>
              <Option value="not-featured">Not featured</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="postedDate"
            label="Posted Date"
            className="w-full md:w-[20%] theme-form-item relative mb-0 manage-job"
          >
            <RangePicker format="YY-MM-DD" placeholder={['Start', 'End']} />
          </Form.Item>
          <Form.Item
            name="expiryDate"
            label="Expiry Date"
            className="w-full md:w-[20%] theme-form-item relative mb-0 manage-job"
          >
            <RangePicker format="YY-MM-DD" placeholder={['Start', 'End']} />
          </Form.Item>
          <Form.Item
            name="postedBy"
            label="Posted By"
            className="w-full md:w-[15%] theme-form-item mb-0"
          >
            <Input
              type="text"
              placeholder="Poster Email Address"
              className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
            />
          </Form.Item>
        </div>
        <div className="flex flex-wrap flex-col lg:flex-row justify-between lg:items-end gap-3">
          <div className="flex-1 flex flex-col lg:flex-row gap-3 sm:max-w-full md:max-w-[70%]">
            <Form.Item
              name="status"
              label="Job Status"
              className="w-full md:w-[20%] theme-form-item mb-0"
            >
              <Select placeholder="Job Status">
                <Option value="Active">Active</Option>
                <Option value="Expired">Expired</Option>
                <Option value="Draft">Draft</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="location"
              label="Location"
              className="google-place-autocomplete w-[100%] md:w-[100%]  mb-4 lg:mb-0 ml-0"
            >
              <GooglePlacesAutocomplete placeholder="Enter Location" />
            </Form.Item>
            <Form.Item
              name="jobCode"
              label="Job Code"
              className="w-full md:w-[50%] theme-form-item mb-0"
            >
              <Input
                type="text"
                placeholder="Job Code"
                className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
              />
            </Form.Item>
          </div>
          <div className="w-full md:w-[16%] flex justify-end">
            <div className="flex items-center">
              <button className="bg-voyage py-3 px-6 font-sans text-white text-xs font-bold rounded mr-2">
                Filter
              </button>
              <button
                onClick={() => {
                  form.resetFields()
                  setFilters({})
                }}
                type="button"
                className="bg-bgSecondaryButton py-3 px-6 text-voyage font-bold rounded text-xs font-sans"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </Form>
      <CreditDetail userProfile={userProfile} user={user} />
      <div className="rounded-lg bg-white shadow-profileCard mt-12">
        <div className="block md:flex px-4 py-4 border-b border-bgSecondaryButton justify-between">
          <div className="flex items-center">
            <img src="/icons/icon-application.svg" className="mb-0" />
            <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
              Your Jobs
            </h1>
          </div>
          <div className="mt-6 md:mt-0 flex flex-wrap items-center justify-between">
            <div className="md:block relative border border-[#C4C4C4] rounded focus:border-merlot px-2 py-1 sort-select sort-by-text mb-2 mr-2">
              <span className="absolute z-10 font-sans font-semibold text-[#6A6A6A] mt-1">
                Sort By:
              </span>
              <Select
                style={{ width: '100%' }}
                defaultValue={sortBy}
                onChange={val => setSortBy(val)}
                className="bg-transparent z-20 relative"
              >
                <Option value="title">Title</Option>
                <Option value="views">Views</Option>
                <Option value="applications">Applications</Option>
                <Option value="postedDate">Posted Date</Option>
                <Option value="expiryDate">Expiry Date</Option>
                <Option value="postedBy">Posted By</Option>
              </Select>
            </div>
            <div className="flex items-center justify-end mb-2">
              {/* <Link to="/employer-dashboard/jobs/create"> */}
              <button
                className="bg-voyage py-3 px-3 font-sans text-white text-last font-bold rounded min-w-[82px]"
                onClick={postJobHandler}
              >
                Post Job
              </button>
              {/* </Link> */}
              {jobs?.length ? (
                <CsvDownloader
                  className="block bg-bgSecondaryButton py-3 px-3 text-voyage font-bold rounded text-xs font-sans ml-2"
                  filename="jobs.csv"
                  wrapColumnChar='"'
                  columns={csvHeader}
                  datas={exportHandler}
                  text="EXPORT"
                />
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
        {loading ? (
          <div
            className="w-full min-h-700px bg-no-repeat bg-cover"
            style={{ backgroundImage: `url(/loaders/manage-jobs-loader.png)` }}
          />
        ) : (
          <JobTable
            user={user}
            userProfile={userProfile}
            products={products}
            hasPagination={true}
            jobs={jobs}
            setJobs={setJobs}
            archiveJob={archiveJob}
            deleteJob={deleteJob}
            archiveJobId={archiveJobId}
            deleteJobId={deleteJobId}
            filters={filters}
            page={page}
            pageSize={pageSize}
            total={total}
            onChangePage={handleChangePage}
            onChangePageSize={handleChangePageSize}
          />
        )}
      </div>
    </div>
  )
}
export default ManageJobsComponent
