import React from 'react'
import ManageJobsComponent from '../../../components/employerDashboard/jobs/manage'
import Layout from '../../../components/employerDashboard/layout'
const ManageJobPage = ({ location }) => {
  return (
    <Layout title="Jobs | MedReps.com" location={location}>
      <ManageJobsComponent />
    </Layout>
  )
}
export default ManageJobPage
